module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-amplitude-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"d7ca921bc1461b83beb6e1c02578451c","head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"eventTypes":{"outboundLinkClick":"OUTBOUND_LINK_CLICK","pageView":"PAGE_VIEW"},"amplitudeConfig":{"saveEvents":true,"includeUtm":true,"includeReferrer":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"8cc34538db1dffeba1532eed3848ede1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0cab3c969b41f6c894457fa27f9b8015"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
