// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-set-password-[token]-js": () => import("./../../../src/pages/set_password/[token].js" /* webpackChunkName: "component---src-pages-set-password-[token]-js" */),
  "component---src-pages-setnew-password-js": () => import("./../../../src/pages/setnew-password.js" /* webpackChunkName: "component---src-pages-setnew-password-js" */)
}

